import { NextSeo } from 'next-seo'
import { Fragment } from 'react'
import { Box, LoginBox, STATEMINTS_APP_NAME } from 'ui'

export default function LoginPage() {
    return (
        <Fragment>
            <NextSeo title="Statemints - Login" />
            <Box
                bg="raven_black"
                padding="sm"
                bRadius="1"
                alignItems="center"
                css={{
                    width: '95%',
                    borderRadius: '30rem',
                    maxWidth: '650rem',
                    m: '0 auto',
                    position: 'fixed',
                    top: '50%',
                    right: 0,
                    left: 0,
                    transform: 'translateY(-50%)',
                    '@sm': {
                        padding: '40rem 60rem'
                    }
                }}
                boxShadowed
            >
                <LoginBox appName={STATEMINTS_APP_NAME} />
            </Box>
        </Fragment>
    )
}
